<template>
    <div>
        <table>
            <tr v-for="rowIndex in chunkSize"
                :key="rowIndex"
            >
                <td v-for="colIndex in chunkSize"
                    :key="colIndex"
                >
                    <div :style='{ backgroundImage: `url(${getImage(rowIndex, colIndex)})` }'
                         class="layout__symbol"
                    >
                        {{ index(rowIndex, colIndex) }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        numbers: {
            type: Array,
            required: true,
        },
        resultNumbers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            chunkSize: 6,
            symbol: {
                size: 64
            }
        }
    },
    computed: {
        numberImagePath() {
            return require(`@/assets/games/mini/keno/number.png`);
        },
        selectedNumberImagePath() {
            return require(`@/assets/games/mini/keno/selected.png`);
        },
        resultNumberImagePath() {
            return require(`@/assets/games/mini/keno/result.png`);
        },
        winNumberImagePath() {
            return require(`@/assets/games/mini/keno/win.png`);
        }
    },
    methods: {
        getImage(rowIndex, colIndex) {
            const index = this.index(rowIndex, colIndex);
            if (this.isWin(index)) {
                return this.winNumberImagePath;
            } else if (this.isSelected(index)) {
                return this.selectedNumberImagePath;
            } else if (this.isResult(index)) {
                return this.resultNumberImagePath;
            }

            return this.numberImagePath;
        },
        isWin(index) {
            return this.isSelected(index) && this.isResult(index);
        },
        isResult(index) {
            if (this.resultNumbers === null) {
                return false;
            }
            return this.resultNumbers.includes(index);
        },
        isSelected(index) {
            if (this.numbers === null) {
                return false;
            }

            return this.numbers.includes(index);
        },
        index(rowIndex, colIndex) {
            return rowIndex * this.chunkSize + colIndex;
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.symbol.size * this.chunkSize);
        this.$store.dispatch('workspace/setWidth', this.symbol.size * this.chunkSize);
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    color: white;
}
</style>

<template>
    <workspace-wrapper>
        <template #workspace>
            <mini-keno-workspace :numbers="stateData.roundResult.numbers"
                             :result-numbers="stateData.roundResult.resultNumbers"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import MiniKenoWorkspace from "./MiniKenoWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        numbers: [],
                        resultNumbers: [],
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        MiniKenoWorkspace,
    }
}
</script>

<style scoped>

</style>
